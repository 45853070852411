.site-layout-content {
  min-height: 100%;
  padding: 24px;
  background-color: white;
  border-radius: 25px;
  --margin-top: 15px;
}

.ant-layout-content {
  padding-top: 15px ;
}