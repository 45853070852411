/* Aggiungi il seguente CSS nel tuo file CSS o nel tuo modulo di stile in React */

.filter-container {
    background: #f0f2f5;
    padding: 24px;
    border-radius: 8px;
  }
  
  .filter-container h2 {
    margin-bottom: 16px;
  }
  
  .filter-container .ant-row {
    margin-top: 16px;
  }
  
  .filter-container .ant-checkbox-group {
    width: 100%;
  }
  
  .filter-container .ant-col {
    display: flex;
    align-items: center;
  }
  
  .filter-container .ant-checkbox-wrapper {
    margin-right: 8px;
  }
  
  .filter-container .ant-checkbox {
    margin-right: 4px;
  }
  
  .filter-container .ant-btn {
    width: 100%;
    margin-left: 16px;
  }